<template>
  <div class="edit-tax-page">
    <BackTitle confirm-click @click="goBack">Edit tax</BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard v-else permission="taxes.edit" v-slot="{ isAvailable }">
      <TaxForm
        :value="tax"
        :is-submitting="isSubmitting"
        has-delete-button
        :is-disabled="!isAvailable"
        @submit="save"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import TaxForm from "@/components/taxes/TaxForm";
import { mapActions, mapState } from "vuex";
import Guard from "@/components/common/Guard";

export default {
  name: "CreateTaxPage",
  components: { Guard, TaxForm, BackTitle },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      tax: (state) => state.taxes.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchTaxById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      updateTax: "taxes/updateTax",
      fetchTaxById: "taxes/fetchTaxById",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateTax({ id, data });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Taxes",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-tax-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
